import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

export default function DownloadAppCTA() {
  const { footerImg } = useStaticQuery(graphql`
        query FooterCalloutQuery {
            footerImg: file(name: { eq: "partnership-footer-hero" }) {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
    `);

  return (
    <div className={`opacity-50`}>
      <GatsbyImage image={getImage(footerImg)} alt='' />
    </div>
  );
}
