import React, { useState } from 'react';
import { Link } from 'gatsby';
import { useForm } from 'react-hook-form';
import InputField from '../atoms/inputs/InputField';
import Headline from '../atoms/typography/Headline';
import Button from '../atoms/inputs/Button';

function PartnershipSignUp() {
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit
  } = useForm({
    criteriaMode: 'all'
  });
  const [isRegistered, setIsRegistered] = useState(false);
  const fullNameRegister = register('full-name', {
    required: 'This field is required'
  });
  const websiteRegister = register('website');
  const emailRegister = register('email', {
    required: 'This field is required',
    pattern: { value: /^\S+@\S+$/, message: 'Please enter a valid email' }
  });

  const onSubmit = async (data) => {
    // console.log({ data });
    // return;
    const formData = {
      interest: 'partnership',
      name: data['full-name'] || 'Partnership Signup',
      email: data.email,
      website: data.website || ''
    };
    const url = `.netlify/functions/partnership-signup/`;
    const options = {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({
        data: formData
      }),
      headers: {
        'Content-Type': 'application/vnd.api+json'
      }
    };

    try {
      await fetch(url, options)
        .then((res) => res.json())
        .catch((err) => console.log(err.message));
      setIsRegistered(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  if (isRegistered)
    return (
      <div className='min-h-[288px] flex items-center justify-center relative'>
        <Headline
          spanText='Thanks for Signing Up'
          spanColor='#6A67FF'
          headerText=''
          className='mb-4 text-center backdrop-contrast-200 p-2 rounded-lg'
        />
      </div>
    );

  return (
    <div
      id='partnership-sign-up-form'
      className={`relative z-10 text-center bg-white px-6 lg:px-10 py-4 lg:py-8  xl:py-16 rounded-3xl shadow-md`}
    >
      <Headline spanText={`Interested?`} className={`mb-4`} />
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <div className={`flex flex-col sm:flex-row`}>
          <div className='min-w-0 flex-1'>
            <label htmlFor='email' className='sr-only'>
              Email address
            </label>
            <p className={`mb-4 text-sm md:text-base text-grey-25`}>
              {`Register your interest with our Partnership Opportunities`}
            </p>
            <div className='grid grid-flow-row gap-4'>
              <div className={`flex flex-col`}>
                <InputField
                  id='full-name'
                  type='text'
                  placeholder='Full name...'
                  {...fullNameRegister}
                  value={watch('full-name', '')}
                  error={errors['full-name']}
                  required
                />
              </div>
              <div className={`flex flex-col`}>
                <InputField
                  id='website'
                  type='text'
                  placeholder='Website...'
                  {...websiteRegister}
                  value={watch('website', '')}
                  error={errors['website']}
                />
              </div>
              <div className={`flex flex-col`}>
                <InputField
                  id='email'
                  type='email'
                  placeholder='Email address...'
                  {...emailRegister}
                  value={watch('email', '')}
                  error={errors.email}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <p
          className={`my-3 text-xs sm:mt-3 text-grey-25 font-light max-w-xs md:max-w-[unset]`}
        >
          By providing your email, you agree to our{' '}
          <Link to='/legal/terms' className=' text-highlight'>
            terms of service
          </Link>
          .
        </p>
        <div className={`mt-4 flex justify-center sm:justify-left`}>
          <Button
            label='Learn more'
            id='email-sign-up'
            className='min-w-[180px] text-lg py-1 lg:py-2 lg:text-base'
          />
        </div>
      </form>
    </div>
  );
}

export default PartnershipSignUp;
